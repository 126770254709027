import React, { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { Button, Layout, Menu, Dropdown, Modal, Avatar, MenuProps } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import IntraAILogo from '../../assets/images/logos/intra_ai_logo_128x128.png';
import { Link, useLocation } from 'react-router-dom';
import { PATH } from '../../constants/paths';

const { Header } = Layout;
const { confirm } = Modal;

export const Navbar: React.FC = () => {
  const { isAuthenticated, user, logout } = useContext(AuthContext);
  const location = useLocation();

  const showLogoutConfirm = () => {
    confirm({
      title: 'Are you sure you want to log out?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        logout();
      },
    });
  };

  // Define menu items for guests
  const guestMenuItems: MenuProps['items'] = [
    {
      key: 'login',
      label: <Link to={PATH.LOGIN}>Login</Link>,
    },
  ];

  // Define menu items for authenticated users
  const userMenuItems: MenuProps['items'] = [
    {
      key: 'profile',
      label: <span>{user?.email}</span>,
    },
    {
      type: 'divider',
      key: 'divider1',
    },
    {
      key: 'profile-page',
      label: <Link to={PATH.PROFILE}>Profile</Link>,
    },
    {
      key: 'logout',
      label: 'Logout',
      onClick: showLogoutConfirm,
    },
  ];

  const getSelectedKey = () => {
    const path = location.pathname;
    if (path === PATH.HOME) return 'home';
    if (path.startsWith(PATH.ABOUT)) return 'about-us';
    if (path.startsWith(PATH.FAQ)) return 'faq';
    if (path.startsWith(PATH.CONTACT)) return 'contact';
    if (path.startsWith(PATH.LOGIN)) return 'login';
    // Add more mappings as needed for other menu items
    return undefined; // Return undefined instead of "home" for unmatched paths
  };

  const selectedKey = getSelectedKey();

  return (
    <Header style={styles.header}>
      <Link to={PATH.HOME}>
        <div style={styles.logo}>
          <img src={IntraAILogo} alt="LOGO" style={{ marginRight: '0px', height: '64px' }} />
          <span style={styles.intra}>Intra</span>
          <span style={styles.ai}>AI</span>
        </div>
      </Link>

      <div style={styles.menuContainer}>
        {/* Main Navigation Menu */}
        <Menu
          theme="light"
          mode="horizontal"
          style={styles.menu}
          selectedKeys={selectedKey ? [selectedKey] : []} // Use empty array if no key is selected
          items={[
            { key: 'home', label: <Link to={PATH.HOME}>Home</Link> },
            { key: 'about-us', label: <Link to={PATH.ABOUT}>About Us</Link> },
            { key: 'faq', label: <Link to={PATH.FAQ}>FAQ</Link> },
            { key: 'contact', label: <Link to={PATH.CONTACT}>Contact</Link> },
          ]}
        />

        {/* "Try it now!" Button */}
        <Link to={PATH.CHAT_INTERFACE}>
          <Button style={styles.contactUsButton}>Try it now!</Button>
        </Link>

        {/* User Avatar with Dropdown Menu */}
        <Dropdown
          menu={{
            items: isAuthenticated ? userMenuItems : guestMenuItems,
          }}
          placement="bottomRight"
          trigger={['click']}
        >
          <Avatar style={{ marginLeft: '15px', cursor: 'pointer' }} icon={<UserOutlined />} />
        </Dropdown>
      </div>
    </Header>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  header: {
    position: 'fixed',
    zIndex: 999,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: '0 20px',
    borderBottom: '1px solid #f0f0f0',
  },
  logo: {
    fontSize: '20px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
  },
  intra: {
    color: '#4286F4',
    marginRight: '5px',
    fontSize: '20px',
    fontWeight: 'bold',
    userSelect: 'none',
  },
  ai: {
    color: 'black',
    fontSize: '20px',
    fontWeight: 'bold',
    userSelect: 'none',
  },
  menuContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexGrow: 1,
    marginLeft: 'auto',
  },
  menu: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    borderBottom: 'none',
  },
  contactUsButton: {
    backgroundColor: '#4286F4',
    color: 'white',
    marginLeft: '15px',
  },
};
