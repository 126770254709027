import React, { useState, useEffect } from 'react';
import { Modal, Button, Typography, Divider } from 'antd';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const { Text, Title } = Typography;

const CookieConsent: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const userConsent = Cookies.get('userConsent');
    if (!userConsent) {
      setIsVisible(true); // Show modal if no consent is found
    }
  }, []);

  const handleAcceptAll = () => {
    Cookies.set('userConsent', 'true', { expires: 365 });
    Cookies.set('preferences', JSON.stringify({ essential: true, functional: true, analytics: true }), {
      expires: 365,
    });
    setIsVisible(false);
  };

  const handleRejectAll = () => {
    Cookies.set('userConsent', 'false', { expires: 365 });
    Cookies.set('preferences', JSON.stringify({ essential: true, functional: false, analytics: false }), {
      expires: 365,
    });
    setIsVisible(false);
  };

  const handleNavigateToSettings = () => {
    setIsVisible(false);
    navigate('/privacy-settings'); // Navigate to privacy settings page
  };

  const handleNavigateToPrivacy = () => {
    setIsVisible(false);
    navigate('/privacy'); // Navigate to privacy settings page
  };

  return (
    <Modal
      title={
        <Title level={4} style={{ fontWeight: 'bold', textAlign: 'center' }}>
          Privacy Preferences
        </Title>
      }
      visible={isVisible}
      footer={null}
      closable={false}
      maskClosable={false}
      centered
      width={600}
      bodyStyle={{ padding: '30px', fontSize: '16px', color: '#444' }}
    >
      <div>
        <Text style={{ display: 'block', marginBottom: '15px', textAlign: 'center' }}>
          We use cookies and similar technologies on our website to enhance your experience. Essential cookies are
          required for basic functionality. You can choose to accept all cookies or configure your preferences.
        </Text>
        <Text style={{ display: 'block', marginBottom: '20px', textAlign: 'center' }}>
          By accepting, you agree to the use of functional and analytics cookies. You can also continue without consent,
          which will only enable essential cookies.
        </Text>

        <Divider />

        <Text style={{ display: 'block', marginBottom: '20px', textAlign: 'center' }}>
          If you are under 16 years old, please ask a parent or guardian for consent.
        </Text>

        <div style={{ textAlign: 'center', marginBottom: '30px' }}>
          <Button
            type="primary"
            size="large"
            onClick={handleAcceptAll}
            style={{
              width: '100%',
              backgroundColor: '#0047ab', // Replace with your primary color
              borderColor: '#0047ab',
              color: '#fff',
              borderRadius: '8px',
              marginBottom: '15px',
              height: '48px',
              fontSize: '16px',
            }}
          >
            Accept all
          </Button>

          <Button
            type="default"
            size="large"
            onClick={handleRejectAll}
            style={{
              width: '100%',
              backgroundColor: '#f5f5f5',
              borderColor: '#ccc',
              color: '#333',
              borderRadius: '8px',
              height: '48px',
              fontSize: '16px',
            }}
          >
            Continue without consent
          </Button>
        </div>

        <div style={{ textAlign: 'center' }}>
          <Button type="link" onClick={handleNavigateToSettings} style={{ fontSize: '14px', color: '#0047ab' }}>
            Set privacy settings individually
          </Button>
          <br />
          <Button type="link" onClick={handleNavigateToPrivacy} style={{ fontSize: '14px', color: '#444' }}>
            Privacy policy
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CookieConsent;
