import React, { createContext, useEffect, useState } from 'react';

// Define the User interface
interface User {
  email: string | null;
}

// Define the AuthContext properties
interface AuthContextProps {
  isAuthenticated: boolean;
  token: string | null;
  user: User | null;
  loading: boolean; // Added loading state
  login: (token: string, user: User) => void;
  logout: () => void;
}

// Create the AuthContext with default values
export const AuthContext = createContext<AuthContextProps>({
  isAuthenticated: false,
  token: null,
  user: null,
  loading: true, // Initialize loading to true
  login: () => {},
  logout: () => {},
});

// Define the AuthProvider component
export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Initialize the token state from localStorage
  const [token, setToken] = useState<string | null>(localStorage.getItem('token'));

  // Initialize the user state from localStorage
  const [user, setUser] = useState<User | null>(() => {
    const storedUser = localStorage.getItem('user');
    try {
      return storedUser ? JSON.parse(storedUser) : null;
    } catch (error) {
      console.error('Failed to parse user from localStorage:', error);
      return null;
    }
  });

  // Initialize the loading state
  const [loading, setLoading] = useState<boolean>(true);

  // useEffect to fetch user data if token exists and user is not set
  useEffect(() => {
    const fetchUser = async (storedToken: string) => {
      try {
        const response = await fetch('/api/auth/me', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });

        if (!response.ok) {
          console.error('Failed to fetch user data:', response.statusText);
          return null;
        }

        const data: User = await response.json();
        setUser(data);
        localStorage.setItem('user', JSON.stringify(data));
      } catch (error) {
        console.error('Error fetching user data:', error);
        logout();
      } finally {
        setLoading(false);
      }
    };

    if (token && !user) {
      fetchUser(token);
    } else {
      setLoading(false);
    }
  }, [token]); // Dependency on token

  // Define the login function
  const login = (newToken: string, newUser: User) => {
    setToken(newToken);
    setUser(newUser);
    localStorage.setItem('token', newToken);
    localStorage.setItem('user', JSON.stringify(newUser));
  };

  // Define the logout function
  const logout = () => {
    setToken(null);
    setUser(null);
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  };

  // Determine if the user is authenticated
  const isAuthenticated = !!token;

  return (
    <AuthContext.Provider value={{ isAuthenticated, token, user, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
