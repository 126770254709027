import React, { useState } from 'react';
import { Button, Col, Input, Layout, message, Row, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { MailOutlined } from '@ant-design/icons';
import intraAILogo from '../../assets/images/logos/intra_ai_logo_128x128.png';
import axios from 'axios';

const { Footer } = Layout;
const { Title, Text } = Typography;

export const AppFooter: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubscribe = async () => {
    if (!email) {
      message.error('Please enter a valid email.');
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post('/api/subscribe', { email });
      message.success(response.data.message);
      setEmail('');
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.error) {
        message.error(error.response.data.error);
      } else {
        message.error('An error occurred. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Footer style={styles.footer}>
        <div style={styles.logoContainer}>
          <img src={intraAILogo} alt="Intra AI Logo" style={styles.logo} />
          <span style={styles.intra}>Intra</span>
          <span style={styles.ai}>AI</span>
        </div>

        <Row gutter={[32, 32]} justify="center">
          <Col xs={24} sm={12} md={6}>
            <Title level={5} style={styles.sectionTitle}>
              Company
            </Title>
            <Space direction="vertical" size="middle">
              <Link to="/about">About Us</Link>
              <Link to="/contact">Contact</Link>
              <Link to="/imprint">Imprint</Link>
              <Link to="/privacy">Privacy Policy</Link>
              <Link to="/privacy-settings">Privacy Settings</Link>{' '}
            </Space>
          </Col>

          <Col xs={24} sm={12} md={6}>
            <Title level={5} style={styles.sectionTitle}>
              Product
            </Title>
            <Space direction="vertical" size="middle">
              <Link to="/home">Home</Link>
              <Link to="/pricing">Pricing</Link>
              <Link to="/faq">FAQ</Link>
            </Space>
          </Col>

          <Col xs={24} sm={12} md={6}>
            <Title level={5} style={styles.sectionTitle}>
              Resources
            </Title>
            <Space direction="vertical" size="middle">
              <Link to="/blog">Blog</Link>
              <Link to="/documentation">Docs</Link>
              <Link to="/pubsub">Pub/Sub Service</Link>
              <Link to="/chat-interface">Chat Interface</Link>
            </Space>
          </Col>

          <Col xs={24} sm={12} md={6}>
            <Title level={5} style={styles.sectionTitle}>
              Stay Updated
            </Title>
            <Space direction="vertical" size="middle" style={styles.subscribeSection}>
              <Text>
                Get notified about new updates, products, tips, and tutorials. No spam. You can always unsubscribe.
              </Text>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                prefix={<MailOutlined />}
                style={styles.input}
                autoComplete="new-password"
              />
              <Button
                type="primary"
                icon={<MailOutlined />}
                onClick={handleSubscribe}
                loading={loading}
                style={styles.subscribeButton}
              >
                Subscribe
              </Button>
            </Space>
          </Col>
        </Row>

        <div style={styles.footerBottom}>
          <Text>Intra AI ©2024</Text>
        </div>
      </Footer>
    </>
  );
};

const styles = {
  footer: {
    backgroundColor: '#f8f9fa',
    padding: '40px 50px',
    borderTop: '1px solid #e9ecef',
  } as React.CSSProperties,
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '40px',
    justifyContent: 'center',
  } as React.CSSProperties,
  logo: {
    height: '60px',
    marginRight: '10px',
    alignSelf: 'flex-start',
  } as React.CSSProperties,
  intra: {
    color: '#4286F4',
    fontSize: '24px',
    fontWeight: 'bold',
    marginRight: '5px',
  } as React.CSSProperties,
  ai: {
    color: '#000',
    fontSize: '24px',
    fontWeight: 'bold',
  } as React.CSSProperties,
  sectionTitle: {
    color: '#333',
  },
  subscribeSection: {
    paddingTop: '10px',
  },
  subscribeButton: {
    backgroundColor: '#4286F4',
    color: 'white',
    width: '150px',
  } as React.CSSProperties,
  footerBottom: {
    textAlign: 'center',
    marginTop: '40px',
    paddingTop: '20px',
    borderTop: '1px solid #e9ecef',
  } as React.CSSProperties,
  input: {
    width: '100%',
    marginBottom: '10px',
  } as React.CSSProperties,
};

export default AppFooter;
