import React from 'react';
import { Layout } from 'antd';
import { Navbar } from '../../components/layout/Navbar';
import { AppFooter } from '../../components/layout/Footer';
import { useLocation } from 'react-router-dom';

const { Content } = Layout;

export const MainLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();
  const isChatInterfacePage = location.pathname === '/chat';

  return (
    <Layout>
      <Navbar />
      <Content style={{ padding: '50px', marginTop: '64px' }}>{children}</Content>
      {!isChatInterfacePage && <AppFooter />}
    </Layout>
  );
};
