import React, { lazy, Suspense } from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { MainLayout } from '../../pages/layouts/MainLayout';
import { Loading } from '../loading';
import { PATH } from '../../constants/paths';
import { Route, Routes } from 'react-router-dom';
import { DocumentationLayout } from '../layout/DocumentationLayout';
import ProtectedRoute from './protectedRoute';
import CookieConsent from '../layout/CookieConsent';
import ScrollToTop from '../navigation/ScrollToTop';
import ScrollToTopButton from '../navigation/ScrollToTopButton';

// Static pages
const FaqPage = lazy(() => import('../../pages/static-pages/FaqPage'));
const HomePage = lazy(() => import('../../pages/home/HomePage'));
const PricingPage = lazy(() => import('../../pages/static-pages/PricingPage'));
const ImprintPage = lazy(() => import('../../pages/legal/ImprintPage'));
const PrivacyPolicyPage = lazy(() => import('../../pages/legal/PrivacyPolicyPage'));
const AboutUsPage = lazy(() => import('../../pages/about-us/AboutPage'));
const ProfilePage = lazy(() => import('../../pages/profile/ProfilePage'));

// Auth pages
const LoginPage = lazy(() => import('../../pages/auth-pages/LoginPage'));
const RegisterPage = lazy(() => import('../../pages/auth-pages/RegisterPage'));
const ForgotPasswordPage = lazy(() => import('../../pages/auth-pages/ForgotPassword'));
const ResetPasswordPage = lazy(() => import('../../pages/auth-pages/ResetPassword'));

// Cookies
const PrivacySettingsPage = lazy(() => import('../../pages/legal/PrivacySettingsPage'));

// Blog pages
const BlogHomePage = lazy(() => import('../../pages/blog/BlogHomePage'));

// Contact Us
const ContactUsPage = lazy(() => import('../../pages/contact-us/ContactUsPage'));

// Documentation pages
const DocumentationHomePage = lazy(() => import('../../pages/documentation/DocumentationHomePage'));

// Chat interface pages
const ChatInterfacePage = lazy(() => import('../../pages/chat/ChatInterfacePage'));

// Error pages
const NotFoundPage = lazy(() => import('../../pages/error-pages/NotFoundPage'));

// Email Verification page
const VerifyEmailPage = lazy(() => import('../../pages/auth-pages/VerificationPage'));

const helmetContext = {};

export const AppRoutes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <HelmetProvider context={helmetContext}>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta httpEquiv="Content-Language" content="en" />
          <meta name="author" content="Intra AI" />
          <title>Intra AI</title>
          <link rel="canonical" href="https://intra-ai.de/" />
        </Helmet>

        <MainLayout>
          <CookieConsent />

          <Suspense fallback={<Loading />}>
            <Routes>
              {/* Static page routes */}
              <Route path={PATH.HOME} element={<HomePage />} />
              <Route path={PATH.FAQ} element={<FaqPage />} />
              <Route path={PATH.PRICING} element={<PricingPage />} />
              <Route path={PATH.IMPRINT} element={<ImprintPage />} />
              <Route path={PATH.PRIVACY} element={<PrivacyPolicyPage />} />
              <Route path={PATH.ABOUT} element={<AboutUsPage />} />

              {/* Auth page routes */}
              <Route path={PATH.LOGIN} element={<LoginPage />} />
              <Route path={PATH.REGISTER} element={<RegisterPage />} />
              <Route path={PATH.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
              <Route path={PATH.RESET_PASSWORD} element={<ResetPasswordPage />} />

              {/* Cookies */}
              <Route path={PATH.PRIVACY_SETTINGS} element={<PrivacySettingsPage />} />

              {/* Email verification route */}
              <Route path={PATH.VERIFY_EMAIL} element={<VerifyEmailPage />} />

              {/* Protected Profile page */}
              <Route
                path={PATH.PROFILE}
                element={
                  <ProtectedRoute>
                    <ProfilePage />
                  </ProtectedRoute>
                }
              />

              {/* Blog routes */}
              <Route path={PATH.BLOG} element={<BlogHomePage />} />

              {/* Contact us route */}
              <Route path={PATH.CONTACT} element={<ContactUsPage />} />

              {/* Documentation routes */}
              <Route path={PATH.DOCUMENTATION} element={<DocumentationLayout />}>
                <Route index element={<DocumentationHomePage />} />
              </Route>

              {/* Chat route */}
              <Route
                path={PATH.CHAT_INTERFACE}
                element={
                  <ProtectedRoute>
                    <ChatInterfacePage />
                  </ProtectedRoute>
                }
              />

              {/* Error routes */}
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </Suspense>
        </MainLayout>
        <ScrollToTopButton />
      </HelmetProvider>
    </BrowserRouter>
  );
};
