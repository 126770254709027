import React from 'react';
import { ConfigProvider } from 'antd';
import { AppRoutes } from './components/routes';
import { AuthProvider } from './contexts/AuthContext';
import { GoogleOAuthProvider } from '@react-oauth/google';

const App: React.FC = () => (
  <ConfigProvider
    theme={{
      token: {
        colorBgBase: '#ffffff',
        colorBgContainer: '#ffffff',
        colorBgLayout: '#ffffff',
      },
    }}
  >
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID!}>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </GoogleOAuthProvider>
  </ConfigProvider>
);

export default App;
