import { Layout, Menu } from 'antd';
import { Link, Outlet } from 'react-router-dom';
import { PATH } from '../../constants/paths';
import React, { useState } from 'react';
import { ApiOutlined, HomeOutlined, MenuOutlined, SettingOutlined } from '@ant-design/icons';

const { Sider, Content } = Layout;

export const DocumentationLayout: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);

  const menuItems = [
    {
      key: 'home',
      icon: <HomeOutlined />,
      label: <Link to={PATH.DOCUMENTATION}>Home</Link>,
    },
    {
      key: 'setup',
      icon: <SettingOutlined />,
      label: <Link to={`${PATH.DOCUMENTATION}/setup`}>Setup</Link>,
    },
    {
      key: 'api',
      icon: <ApiOutlined />,
      label: 'API',
      children: [
        {
          key: 'installation',
          label: <Link to={`${PATH.DOCUMENTATION}/api/services`}>Installation</Link>,
        },
        {
          key: 'configuration',
          label: <Link to={`${PATH.DOCUMENTATION}/api/configuration`}>Configuration</Link>,
        },
      ],
    },
  ];

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        width={200}
        collapsible
        collapsed={collapsed}
        onCollapse={setCollapsed}
        trigger={null}
        style={{
          background: '#fff',
          position: 'fixed',
          height: '100vh',
          top: '64px',
          left: 0,
          zIndex: 1,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            padding: '10px 0',
            paddingLeft: collapsed ? '20px' : '15px',
          }}
        >
          <MenuOutlined
            onClick={toggleCollapsed}
            style={{
              fontSize: '24px',
              cursor: 'pointer',
              transition: '0.3s',
              padding: '8px',
              borderRadius: '50%',
            }}
            className="burger-icon"
          />
        </div>
        <Menu
          mode="inline"
          defaultSelectedKeys={['home']}
          style={{ height: '100%', borderRight: 0 }}
          items={menuItems}
        />
      </Sider>
      <Layout
        style={{
          marginLeft: collapsed ? 80 : 200,
          marginTop: '64px',
          padding: '0 24px 24px',
        }}
      >
        <Content
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
