export const PATH = {
  ABOUT: '/about',
  BLOG: '/blog',
  CONTACT: '/contact',
  CHAT_INTERFACE: '/chat',
  DOCUMENTATION: '/documentation',
  FAQ: '/faq',
  FORGOT_PASSWORD: '/forgot-password',
  HOME: '/',
  IMPRINT: '/imprint',
  LOGIN: '/login',
  REGISTER: '/register',
  RESET_PASSWORD: '/reset-password',
  PRICING: '/pricing',
  PRIVACY: '/privacy',
  PRIVACY_SETTINGS: '/privacy-settings',
  PROFILE: '/profile',
  PUBSUB: '/pubsub',
  NOT_FOUND: '/404',
  VERIFY_EMAIL: '/verify-email',
};
